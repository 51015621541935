import React from 'react';
import shemaAss from '../../../images/shemaAss.jpg';

function Structure() {
  return (
    <section className='structure'>
      <img src={shemaAss} className='structure__img' />
    </section>
  );
}

export default Structure;

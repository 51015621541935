// import React from 'react';

const statistic = [
  {
    year: '1991',
    trip: 17,
    rescued: 3,
    id: 1,
  },
  {
    year: '1992',
    trip: 57,
    rescued: 12,
    id: 2,
  },
  {
    year: '1993',
    trip: 42,
    rescued: 9,
    id: 3,
  },
  {
    year: '1994',
    trip: 56,
    rescued: 17,
    id: 4,
  },
  {
    year: '1995',
    trip: 27,
    rescued: 8,
    id: 5,
  },
  {
    year: '1996',
    trip: 39,
    rescued: 11,
    id: 6,
  },
  {
    year: '1997',
    trip: 56,
    rescued: 13,
    id: 7,
  },
  {
    year: '1998',
    trip: 112,
    rescued: 18,
    id: 8,
  },
  {
    year: '1999',
    trip: 180,
    rescued: 21,
    id: 9,
  },
  {
    year: '2000',
    trip: 217,
    rescued: 23,
    id: 10,
  },
  {
    year: '2001',
    trip: 845,
    rescued: 26,
    id: 11,
  },
  {
    year: '2002',
    trip: 1462,
    rescued: 56,
    id: 12,
  },
  {
    year: '2003',
    trip: 1670,
    rescued: 72,
    id: 13,
  },
  {
    year: '2004',
    trip: 1169,
    rescued: 60,
    id: 14,
  },
  {
    year: '2005',
    trip: 1252,
    rescued: 64,
    id: 15,
  },
  {
    year: '2006',
    trip: 1211,
    rescued: 184,
    id: 16,
  },
  {
    year: '2007',
    trip: 1398,
    rescued: 233,
    id: 17,
  },
  {
    year: '2008',
    trip: 2211,
    rescued: 293,
    id: 18,
  },
  {
    year: '2009',
    trip: 2071,
    rescued: 319,
    id: 19,
  },
  {
    year: '2010',
    trip: 2116,
    rescued: 263,
    id: 20,
  },
  {
    year: '2011',
    trip: 1976,
    rescued: 223,
    id: 21,
  },
  {
    year: '2012',
    trip: 1915,
    rescued: 212,
    id: 22,
  },
  {
    year: '2013',
    trip: 1915,
    rescued: 243,
    id: 23,
  },
  {
    year: '2014',
    trip: 2108,
    rescued: 227,
    id: 24,
  },
  {
    year: '2015',
    trip: 1888,
    rescued: 193,
    id: 25,
  },
  {
    year: '2016',
    trip: 1955,
    rescued: 195,
    id: 26,
  },
  {
    year: '2017',
    trip: 1864,
    rescued: 184,
    id: 27,
  },
  {
    year: '2018',
    trip: 2085,
    rescued: 193,
    id: 28,
  },
  {
    year: '2019',
    trip: 1685,
    rescued: 206,
    id: 29,
  },
  {
    year: '2020',
    trip: 1710,
    rescued: 198,
    id: 30,
  },
  {
    year: '2021',
    trip: 1955,
    rescued: 206,
    id: 31,
  },
  {
    year: '2022',
    trip: 1865,
    rescued: 203,
    id: 32,
  },
  {
    year: '2023',
    trip: 1745,
    rescued: 164,
    id: 33,
  },
];

export default statistic;

import React from 'react';

function History() {
  return (
    <section className='history'>
      <p>
        Приказом начальника штаба – заместителя начальника гражданской обороны
        г. Северодвинска подполковника В. Комисарова № 81 от 19 октября 1990
        года при штабе ГО создано малое государственное предприятие «Спасатель».
        Изначально предполагалось, что финансировать службу спасения будут
        городской бюджет и градообразующие предприятия, но после появления на
        свет «содержатели» оставили детище на произвол судьбы. С этого времени
        развитие «Спасателя» пошло двумя параллельными курсами – службы спасения
        и производства.
      </p>
      <p>
        В 1991 году пять членов спасотряда принимали участие в ликвидации
        последствий землетрясения в Амбралаурском районе Грузии. 19.08.1992
        Постановлением Северодвинской городской мэрии №87/561 МГП «Спасатель»
        было перерегистрировано в ТОО Оперативно-спасательная служба
        «Спасатель».
      </p>
      <p>
        28.08.1992 года заместителем директора – начальником штаба гражданской
        обороны был назначен Вадим Вадимович Серегин, который проработал до мая
        1995 г. а позднее возглавлял ГИМС МЧС РФ.
      </p>
      <p>
        С 1994 года ОСС «Спасатель» стало членом Ассоциации спасательных
        формирований России. В октябре 1994 года «Спасатель» впервые был
        аттестован на право проведения аварийно-спасательных работ
        Межведомственной комиссией ассоциации спасательных формирований России в
        г. Москве, к тому времени было аттестовано всего 8 аварийно-спасательных
        формирований в стране.
      </p>
      <p>
        В 1998 году по приказу № 23 от 16.12 1998 г. была проведена
        перерегистрация ТОО в ООО ОСС «Спасатель».
      </p>
      <p>
        В 2000 году из ООО ОСС «Спасатель» было выделена аварийно-спасательная
        служба и организовано муниципальное унитарное предприятие
        «Аварийно-спасательная служба Северодвинска».
      </p>
      <p>
        Муниципальное учреждение «Аварийно-спасательная служба Северодвинска»
        образовано постановлением Главы администрации МО «Северодвинск» № 143 от
        14.12.2005 года путем реорганизации муниципального унитарного
        предприятия «Аварийно-спасательная служба Северодвинска». Учредителем
        АССС является Комитет Жилищно-коммунального хозяйства, транспорта и
        связи и Комитет по управлению муниципальным имуществом администрации
        Северодвинска.
      </p>
      <p>
        Постановлением Администрации МО Северодвинск № 402-па от 20.01.2011 с
        01.01.2012 создано муниципальное казенное учреждение
        «Аварийно-спасательная служба Северодвинска» путем изменения типа
        муниципального учреждения «Аварийно-спасательная служба Северодвинска».
      </p>
      <p>
        На 1 января 2023 года в службе работает 44 человека, из них 24
        спасателя, 6 оперативных дежурных.
      </p>
    </section>
  );
}

export default History;

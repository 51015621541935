import React from 'react';

function Scholarships() {
  return (
    <section className='scholarships'>
      <p className='scholarships__text'>
        В МКУ «АСС Северодвинска» стипендии обучающимся не предусмотрены.
      </p>
      <p className='scholarships__text'>
        В МКУ «АСС Северодвинска» общежитий, интернатов и иных жилых помещений
        нет. Зданиями общежития и интерната, в том числе приспособленными для
        использования инвалидами и лицами с ограниченными возможностями
        здоровья, учреждение не располагает.
      </p>
      <p className='scholarships__text'>
        Трудоустройством выпускников Учреждение не занимается, в связи со
        спецификой реализуемых образовательных программ в области ГО и РСЧС.
      </p>
    </section>
  );
}

export default Scholarships;

import React from 'react';
// import HeaderMain from '../HeaderMain/HeaderMain.js';
// import newsList from '../../utils/newsList';

function NoPage() {
  //   console.log(newsList)
  return (
    <section className='noPage'>
      <h4 className='noPage__text'> Страница уже в пути и скоро появится</h4>
    </section>
  );
}

export default NoPage;

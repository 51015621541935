import React from 'react';

function WorksAndServices() {
  return (
    <section className='worksAndServices'>
      <h3>
        МКУ «АСС Северодвинска» как аварийно-спасательное формирование
        аттестовано на проведение аварийно-спасательных работ (свидетельство об
        аттестации от 09.04.2021 № 08773):
      </h3>
      <ol className='worksAndServices__list'>
        <li className='worksAndServices__point'>
          Аварийно-спасательные работы, связанные с тушением пожаров.
        </li>
        <li className='worksAndServices__point'>
          Поисково-спасательные работы.
        </li>
      </ol>

      <h3>Дополнительные работы и услуги</h3>
      <p>
        МКУ «АСС Северодвинска» выполняет некоторые виды работ, не связанные с
        ликвидацией и предотвращением ЧП и ЧС и не являющимися уставными видами
        деятельности.
      </p>
      <p>
        Это работы по деблокации помещений (как показывает практика большинства
        российских служб спасения, они занимают ведущее место в статистике
        выездов).
      </p>
      <p>
        Спасателями МКУ «АСС Северодвинска» выполняются работы по вскрытию
        дверей только по заявкам граждан в случаях, которые могут повлечь за
        собой отрицательные последствия (за закрытой дверью пострадавший,
        ребенок, коммунальная авария, включен газ и т.п.). Также вскрытие дверей
        производится по заявкам пенсионеров и инвалидов. Причем, зачастую за
        вызовом, который, изначально классифицируется как «бытовуха» или помощь
        населению (деблокация дверей пенсионерам и инвалидам) выявляются
        причины, по которым деблокацию необходимо производить незамедлительно
        (включенный газ, коммунальная авария, пострадавшие (больные) оставление
        которых без помощи приведет к их гибели и др.).
      </p>
      <p>
        Как правило, выполнение данного вида работ производится с привлечением
        других экстренных служб – полиции, скорой помощи, аварийных бригад
        Горгаза и ЖКХ.
      </p>
      <p>
        По принятой во всем цивилизованном мире практике, МКУ «АСС
        Северодвинска» выполняет работы по спасению животных. Причем
        специалистами службы данные работы рассматриваются в контексте оказания
        помощи, прежде всего населению. Спасение животных из труднодоступных и
        прочих опасных мест, нахождение в которых несет угрозу их жизни и
        здоровью, способствует восстановлению психологического равновесия
        глубоко переживающих хозяев и просто участливых людей, которых не
        оставляет равнодушными страдание животного
      </p>
    </section>
  );
}

export default WorksAndServices;

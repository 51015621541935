// import React from 'react';
import icon_doc_1 from '../images/icon_doc_1.png';
import docFixPlan from './documents/documentsNews/Prikaz_o_vnesenii_izmenenii_v_plan.pdf'
import news1_1 from './newsPhoto/news1/1.jpg';
import news1_2 from './newsPhoto/news1/2.jpeg';
import news1_3 from './newsPhoto/news1/3.jpg';
import news1_4 from './newsPhoto/news1/4.jpeg';
import news1_5 from './newsPhoto/news1/5.jpeg';
import news1_6 from './newsPhoto/news1/6.jpeg';
import news1_7 from './newsPhoto/news1/7.jpg';
import news2_1 from './newsPhoto/news2/1.jpg';
import news2_2 from './newsPhoto/news2/2.jpg';
import news2_3 from './newsPhoto/news2/3.jpg';
import news2_4 from './newsPhoto/news2/4.jpg';
import news2_5 from './newsPhoto/news2/5.jpg';
import news2_6 from './newsPhoto/news2/6.jpg';
import news2_7 from './newsPhoto/news2/7.jpg';
import news2_8 from './newsPhoto/news2/8.jpg';
import news4_1 from './newsPhoto/news4/1.jpg';
import news5_1 from './newsPhoto/news5/1.jpeg';
import news6_1 from './newsPhoto/news6/1.jpg';
import news6_2 from './newsPhoto/news6/2.jpg';
import news6_3 from './newsPhoto/news6/3.jpg';
import news6_4 from './newsPhoto/news6/4.jpg';
import news6_5 from './newsPhoto/news6/5.jpg';
import news6_6 from './newsPhoto/news6/6.jpg';
import news6_7 from './newsPhoto/news6/7.jpg';
import news6_8 from './newsPhoto/news6/8.jpg';
import news6_9 from './newsPhoto/news6/9.jpg';
import news6_10 from './newsPhoto/news6/10.jpg';
import news6_11 from './newsPhoto/news6/11.jpg';
import news6_12 from './newsPhoto/news6/12.jpg';

const newsList = [
  {
    heading: 'Изменения в плане подготовки на курсах ГО',
    date: '23.09.2024',
    text: [
      'В связи с отсутствием преподователя, внесены изменения в план подготовки должностных лиц на курсах ГО и ПБ МКУ "АСС Северодвинска".',
      'Обучение группы "Должностные лица, входящие в состав сборных пунктов организаций" обучающихся 05-07.11.2024 перносится на 08-10.10.2024',
      'Переносится на 2025 г. обучение групп:',
      '"Работник структурных подразделений, уполномоченных на решение задач в области ГО организаций, отнесенных к категории по ГО, а также продолжающих работу в военное время" обучающихся с 24-26.09.2024 и с 08-10.10.2024',
      '"Руководители спасательных служб, нештатных формирований гражданской обороны, нештатных аварийно-спасательных формирований" обучающихся 22-24.10.2024',

    ],
    photos: [],
    docs: [
      {
        titleDoc: 'Приказ о внесении изменений в план подготовки должностных лиц на курсах ГО',
        typeDoc: icon_doc_1,
        path: docFixPlan,
        docId: 1,
      },
    ],
    newsId: 7,
  },
  {
    heading:
      'Соревнования по ПСР в Ленинградской области',
    date: '25.10.2023',
    text: [
      'С 6 по 8 октября в Ленинградской области прошли соревнования по поисково-спасательным работам "ПСР 2023", где 11 команд из северо - западных регионов России (Ленинградской, Новгородской, Псковский областей, Республики Карелия) "боролись" за звание лучших! Соборная команда Архангельской области "ПСГ-29", под номером 40, в составе которой было 3 спасателя из Северодвинской службы спасения, 3ое из Областной службы им.И.А.Поливаного и 2ое из АКЦ.',
      'Командам предстояло пройти сложные испытания на знания и применение навыков поисково-спасательных работ, транспортировки пострадавших, ориентирование и выживания в труднодоступной местности, а также слаженной, командной работы в условиях неблагоприятных погодных условиях. (просто так уж вышло, погоде не прикажешь).',
      'В результате пройденных испытаний команда заняла почетное второе место. С чем мы ее, конечно же, и поздравляем!.',
      'С нетерпением ждем следующего года,и возможности вновь посоревноваться за звание лучших!',
    ],
    photos: [news6_1, news6_2, news6_3, news6_4, news6_5, news6_6, news6_7, news6_8, news6_9, news6_10, news6_11, news6_12 ],
    newsId: 6,
  },
  {
    heading:
      'Руководитель спасательной службы Северодвинска завершил свою работу',
    date: '17.05.2022',
    text: [
      'Константин Окулов — один из основоположников службы, настоящий энтузиаст своего дела.',
      'Созданная в 1990 году городская служба спасения была уникальным коллективом единомышленников. Начиная свою работу практически на общественных началах, без финансирования, спасатели Северодвинска за 32 года стали настоящими профи своего дела. И во многом уровень мастерства определял лидер команды — Константин Окулов.',
      '28 апреля он официально покинул свой пост и вышел на заслуженный отдых. Глава города Игорь Скубенко лично поблагодарил Константина Окулова за многолетний самоотверженный труд.',
      '— Вы практически создали службу спасения Северодвинска и воспитали множества спасателей — настоящих профессионалов, благодаря работе которых горожане чувствуют себя в безопасности, — подчеркнул Игорь Скубенко.',
      'Новым начальником аварийно-спасательной службы г. Северодвинска назначен Дмитрий Юрьевич Поздяков.',
    ],
    photos: [news5_1],
    newsId: 5,
  },
  {
    heading: 'Экскурсии в службе спасения',
    date: '16.12.2021',
    text: [
      'Спасатели постоянно проводят профилактическую работу с детьми. Экскурсии дошкольников и школьников на службу спасения  стали регулярными. Ребята узнают о том, как работает  служба спасения, о профессии спасатель, знакомятся с оборудованием для работы спасателей',
      'Для записи на экскурсию нужно позвонить по телефону 56-90-24, согласовать время, удобное и для детей, и для спасателей.',
      'Направить в службу спасения заявку.',
    ],
    photos: [news4_1],
    newsId: 4,
  },
  {
    heading: 'Информация для слушателей Курсов ГО',
    date: '19.10.2021',
    text: [
      'Изменения в работе Курсов в 2021 году: перенесены сроки обучения групп слушателей, запланированных на октябрь 2021 года.',
    ],
    photos: [],
    newsId: 3,
  },
  {
    heading: 'Обучение сотрудников Курсов ГО по программе «Первая помощь»',
    date: '10.10.2021',
    text: [
      '9 и 10 октября 2021 года сотрудники Курсов ГО прошли базовый курс обучения по программе Российского Красного Креста «Первая помощь», в соответствии с Международными стандартами по первой помощи и реанимации 20016 МФОККиКП.',
    ],
    photos: [
      news2_1,
      news2_2,
      news2_3,
      news2_4,
      news2_5,
      news2_6,
      news2_7,
      news2_8,
    ],
    newsId: 2,
  },
  {
    heading: 'Открытые соревнования в городе Котлас 08.10.2021 г.',
    date: '08.10.2021',
    text: [
      'В городе Котлас 8 октября 2021 г. проводились Открытые соревнования по выполнению задач газодымозащитной службы среди подразделений государственной противопожарной службы и аварийно-спасательных формирований Архангельской области «Лучшее звено ГЗДС».',
      'Организатором соревнований выступила Котласская служба спасения.',
      'Всего в соревнованиях приняло участие 8 команд, в том числе команда Северодвинской службы спасения. В этом году на соревнования отправились спасатели, работающие на службе недавно — Влад Калаев, Марк Коноплев, Артем Юфряков, представитель команды — Василий Старцев.',
      'Для участников соревнования стали хорошей школой.',
      'По результатам соревнований северодвинская команда заняла 2 место.',
      '1 место — у Котласской службы спасения, третье — у Архангельской областной службы спасения',
    ],
    photos: [news1_1, news1_2, news1_3, news1_4, news1_5, news1_6, news1_7],
    newsId: 1,
  },
];

export default newsList;

import React from 'react';

function Purpose() {
  return (
    <section className='purpose'>
      <h3>
        Целью деятельности учреждения является предоставление муниципальных
        услуг по:
      </h3>
      <ul>
        <li className='purpose__point'>
          организации и проведению аварийно-спасательных работ, направленных на
          спасение жизни и сохранение здоровья людей на территории
          муниципального образования «Северодвинск»;
        </li>
        <li className='purpose__point'>
          организации подготовки и обучения населения в области гражданской
          обороны, защиты от чрезвычайных ситуаций природного и техногенного
          характера.
        </li>
      </ul>
    </section>
  );
}

export default Purpose;

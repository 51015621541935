// import React from 'react';
import foto1 from '../images/gallery/1.jpg';
import foto2 from '../images/gallery/2.jpg';
import foto3 from '../images/gallery/3.jpg';
// import foto4 from '../images/gallery/4.jpg';
import foto5 from '../images/gallery/5.jpg';
// import foto6 from '../images/gallery/6.jpg';
function gallery() {
  return [foto1, foto2, foto3, foto5];
}
export default gallery;

import React from 'react';

function EducationalStandards() {
  return (
    <section className='educationalStandards'>
      <p className='educationalStandards__text'>
        Федеральные государственные образовательные стандарты не используются
      </p>
    </section>
  );
}

export default EducationalStandards;
